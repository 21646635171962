<template>
    <b-container class="p-4 vertical-center">
        <b-row>
            <b-col>
                <div class="text-center">
                    <img style="height: 4.25rem; width: 20rem" src="SmartRacks.svg" class="rounded mb-2">
                </div>
            </b-col>
        </b-row>
        <b-row>
            <div class="link text-center m-auto pt-4">
                <div class="adaptive-text text-center font-weight-bold">
                    Enrollment has been completed successfully, please check your email for a link to download the Smart Racks Mobile App...
                </div>
            </div>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "EnrollSuccessfulView"
}
</script>

<style scoped>
.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.adaptive-text {
    font-size: 2.5em;
}
</style>
